.ResidentCard {
  background-color: var(--primaryColour);
  color: var(--lighthighlightColour);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 7px;
  max-width: 300px;
  max-height: 200px;
  cursor: pointer;
}

.ResidentCard .avatar {
  width: 20%;
}

.ResidentCard .avatar img {
  width: 90%;
  vertical-align: middle;
  
  border-radius: 50%;
}
.ResidentCard .details {
  display: flex;
  flex-direction: column;
}