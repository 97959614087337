@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);

:root {
  --primaryColour: #4cc1bd;
  --lighthighlightColour: white;
  --chartTitleColour: grey;
  --chartSubTitleColour: grey;
  --chartTickColour: grey;
  --chartTickColour: grey;
  --secondaryColour: #dcf5f3;
  --chartBarTitleColour: grey;
  --detailsTextColour: grey;
  --bodyBGColour: white;
  --headerBGColour: white;
  --chartBGColour: lightgray;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.menuItem_container {

  color: grey;
  font-size: 18px;

}

.menuItem_container div {
  padding-top: 10px;
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}*/

.main_logo_header { width: 150px; }

.header_menu_options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 265px;
  color: #4cc1bd;
  align-items: center;
}



.header_menu_options * { margin-left: 15px; }

.main_content_organiser {
  display: flex;
  flex-direction: row;
}



.footer {
    background-color: #4cc1bd;
    background-color: var(--primaryColour);
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: 25px;
    display: none;
    flex-direction: row;
    justify-content: center;
    margin-top: auto;
    bottom: 0px;
    /*display: none;*/
   
}

.main_content_menu{
  padding: 20px;
  min-width: 100px;
  display: flex;
  flex-direction: row;
}

.main_content_organiser {margin-bottom: 40px;}



@media screen and (max-width: 1000px) {
  
  .top_level .main_content_menu {display: none;}

  .footer {display: flex;}

  .main_content_organiser .main_content_menu {display: none;}

  

  .footer .main_content_menu{
    background-color: #4cc1bd;
    background-color: var(--primaryColour);
    color: white;
    position: absolute;
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    bottom: 0px;
    display: flex;
  }



  .footer .main_content_menu * {
    margin-left: 10px;
    margin-right: 10px;
  }

  .main_content_organiser {
    height: 90%;
    overflow-y: scroll;
  }
 
}


.dash_item_border{
    border-radius: 6px;
    border: 1px solid black;
}

.tick {color: var(--chartTickColour);}

.widget_value {
    fill: var(--primaryColour);
 /*   font-size: 40px;*/
    font-weight: 700;
}

.barchart_bar {
    fill: url(#svgGradient);

}
.barchart_bar:hover {
    stroke: var(--primaryColour); 
    stroke-width: 3px;    
}
.barchart_bartext{fill: var(--primaryColour);}
.barchart_bartext_title{fill: var(--chartBarTitleColour);}
.dashboard_chart {
    text-align: center;
    display: inline-block;
    position: relative;
    width: 100%;
    /*padding-bottom: 100%;*/
    /*min-height: 200px;*/
    vertical-align: top;
    overflow: hidden;
    height: 100%;
   /* margin-top: 30px;*/
   flex-grow:1;
}

.fixed-size {
    overflow: scroll;
}

@media screen and (max-width: 1000px) {
    .dashboard_chart {
        width: 100% !important;
        height: 100% !important;
    }
} 



.svg-content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

.chartTitle {
    overflow:hidden;
    display:inline-block;
    color: var(--chartTitleColour);
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 5px;
    font-weight: 600;
    margin-top: 1px;
    min-height: 20px;
}

.chartSubTitle {
    color: var(--chartSubTitleColour);
    font-size: small;
    margin-left: 5px;
    margin-right: 2px;
    margin-bottom: 5px;
    overflow:hidden;
    /*display:inline-block;*/
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 20px;
}

.testitem {
    background-color: #4cc1bd;
}

.dashboard_container {
    display: grid;
    /*grid-template-columns: repeat(4, 250px);*/
    grid-template-rows: repeat(8, 200px);
    grid-template-columns: repeat(5, 220px);
    background-color: lightgray;
    /*max-width: 1520px;*/
    grid-gap: 20px;
    padding: 20px;
    overflow-x: hidden;
    transition: height .15s ease-in-out;
    transition-property: height;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;

}

.dashboard_editing_banner{
    width: 100%;
    margin: 10px;
    padding: 10px;
}

dialog {
    background-color: white;
    padding: 50px;
    min-height: 400px;
    border: none !important;
    border-radius: calc(5px * var(--ratio));
    box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    padding: 1.6rem;
    max-width: 400px;
}

dialog::-webkit-backdrop {
    background-color: #dcf5f3;
    width: 100%;
    height: 100%;
}

dialog::backdrop {
    background-color: #dcf5f3;
    width: 100%;
    height: 100%;
}

.resize_chart {
    cursor: pointer;
    color: black;
}

.greyItem {
    background-color: lightgray !important;
}
.dashboard_item {
    
    
    border: 1px solid black;
    border-radius: 6px;
    background-color: white;
    transition: -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
    overflow: hidden;
    
   /* 
    justify-content: space-between;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    -webkit-column-break-inside: avoid;
    padding: 24px;
    box-sizing: border-box;
    */
}
/*
  .dashboard_item:first-child {
    height: 485px;
  }

  .dashboard_item:nth-child(2) {
    height: 200px;
  }

  .dashboard_item:nth-child(3) {
    height: 265px;
  }
*/

.drag_item {
    cursor: move;
    padding: 10px;
    margin-bottom: 1px;
    transition: all 0.2s;
}

.drag_item:hover { background-color: #dcf5f3!important; }

.col{
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    height:100%;
}

  
.col_span_1 {grid-column: span 1;}
.col_span_2 {grid-column: span 2;}
.col_span_3 {grid-column: span 3;}
.col_span_4 {grid-column: span 4;}
.col_span_5 {grid-column: span 5;}

.row_span_1 {grid-row: span 1;}
.row_span_2 {grid-row: span 2;}
.row_span_3 {grid-row: span 3;}
.row_span_4 {grid-row: span 4;}
.row_span_5 {grid-row: span 5;}


button.reverse {
    display: block;
    margin: 0 auto;
}

.dashboard_widget {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 2%;
}
.dashboard_widget .title { 
    font-weight: 600;
    color: var(--chartTitleColour); }
.dashboard_widget .subtitle { 
    font-size: small;
    color: var(--chartTitleColour); }
.dashboard_widget .direction { color: var(--primaryColour); }
.dashboard_widget .value { 
    color: var(--primaryColour); 
    font-size: 4vh; 
    text-align: center; 
    margin-top: 10px; 
    font-weight: 600; 
}
.dashboard_widget .caption { 
    color: var(--chartTitleColour); font-size: smaller;
}

.red { color: red !important; }


.dashboard_chart .title { 
    font-weight: 600;
    color: var(--chartTitleColour); 
}
.dashboard_chart .subtitle { 
    font-size: small;
    color: var(--chartTitleColour); 
}


@media screen and (max-width: 1000px) {
    
    .dashboard_container {
        display: flex;
        flex-direction: column;
        background-color: lightgray;
        width: 100%;
        position: relative;

    /*
        padding: 10px;
        padding-right: 23px;
        */
    } 
}
div.tooltip {
    position: absolute;
    text-align: center;
    padding: .4rem;
    background: #FFFFFF;
    color: #313639;
    border: 1px solid #313639;
    border-radius: 8px;
    pointer-events: none;
    font-size: 1rem;
    font-style: italic;
}
.barchart_bar { fill: url(#svgGradient); }

.barchart_bar:hover {
    stroke: var(--primaryColour); 
    stroke-width: 3px;    
}

.barchart_bartext{fill: var(--primaryColour);}

.barchart_bartext_title{fill: var(--chartBarTitleColour);}

.barchart_bar-bp-red { fill: red; }

.charter_datatable { width: 100%; }

.charter_datatable th {
    background-color: var(--primaryColour);
    color: var(--lighthighlightColour);
    padding: 2px;
    border-radius: 1px;
}

.charter_datatable td {
    background-color: var(--lighthighlightColour);
    color: var(--chartTickColour);
}
    .care_selected { height:25px }

    .caretooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted #4cc1bd; /* If you want dots under the hoverable text */
        margin:1px;
    }
  
    .caretooltip .caretooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #4cc1bd;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        border: 1px solid white;
        position: absolute;
        z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .caretooltip:hover .caretooltiptext {
        visibility: visible;
    }


    .caretooltip_comment {
        justify-content:center;
        height:100%;
    }

    .caretooltip_comment .caretooltiptext_comment {
        visibility: hidden;
        width: 120px;
        background-color: darkgray;
        color: #fff;
        text-align: center;
        padding: 1px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .caretooltip_comment:hover .caretooltiptext_comment {
        visibility: visible;
    }

    .dashboard_careclick_comment_icon {
        background-color: lightgray;
        color: darkgray;
        font-size: 15px;
        font-weight: bold;
        height: 20px;
        width: 20px;
        border-radius:4px;
        margin:1px;
    }

    .caretooltiptext_comment_title{
        font-weight:bold;
        font-size: 14px;
        color:white;
        text-align:left;
        padding-left: 10px;        
    }
    
    .caretooltiptext_comment_text{
        background-color:white;
        color:darkgray;
        margin: 2px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding:2px;
        text-align:left;
        padding-left: 10px;
    }
.cover {
    display: flex;
    align-items: center;
    height: 50vh;
    justify-content: center;
    background-color: pink;
}

.grid_dash {
    padding: 2%;
    width: 100%;
    height: 100%;
    background-color: var(--chartBGColour);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));   
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.grid_item {
    background-color: white;
    border-radius: 6px;
    padding: 5px;
}

.grid_col_span_2 {
    grid-column: auto / span 2;
}

/*
@media (max-width: 390px), screen and (orientation: portrait) {
*/

@media only screen and (orientation: portrait) {

    .grid_item {
/*        background-color: yellow;*/
        grid-column: auto / span 2;
    }
    .grid_dash {
        grid-template-rows: auto;   
    }

    .grid_dash {
        grid-template-columns: repeat(1, 1fr);
    }

}

@media only screen and (max-width:30em) {

    .grid_item {
        grid-column: auto / span 2;
/*        background-color: red;*/

    }

    .grid_dash {
        grid-template-rows: auto;   
    }
    
}

.carer_container_grid {
    display: grid;
    grid-template-columns: auto 2fr 1fr 1fr;
}

.carer_item {
    font-size: 2vh;
    background-color: white;
    color:gray;
}

header {
    
    display: flex;
    flex-direction: row;
}

body {
    margin: auto;
}

.wrapper {
    position: relative;
    overflow: hidden;
    width: 100px;
    height: 100px; 
    border: 1px solid black;
}

.fade-in { -webkit-animation: fadeIn 1s; animation: fadeIn 1s; }

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
@media screen and (max-width: 1000px) {
    .header_menu_options { display: none; }
}

 .hamburgerMenu {display: block;}
 
 #menuToggle
 {
   display: block;
   position: relative;
   top: 50px;
   left: 50px;
   
   z-index: 1;
   
   -webkit-user-select: none;
   user-select: none;
 }
 
 #menuToggle a
 {
   text-decoration: none;
   color: #232323;
   
   transition: color 0.3s ease;
 }
 
 #menuToggle a:hover
 {
   color: tomato;
 }
 
 
 #menuToggle input
 {
   display: block;
   width: 40px;
   height: 32px;
   position: absolute;
   top: -7px;
   left: -5px;
   
   cursor: pointer;
   
   opacity: 0; /* hide this */
   z-index: 2; /* and place it over the hamburger */
   
   -webkit-touch-callout: none;
 }
 
 /*
  * Just a quick hamburger
  */
 #menuToggle span
 {
   display: block;
   width: 33px;
   height: 4px;
   margin-bottom: 5px;
   position: relative;
   
   background: #cdcdcd;
   border-radius: 3px;
   
   z-index: 1;
   
   -webkit-transform-origin: 4px 0px;
   
           transform-origin: 4px 0px;
   
   transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease,
               -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease;
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease,
               -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 }
 
 #menuToggle span:first-child
 {
   -webkit-transform-origin: 0% 0%;
           transform-origin: 0% 0%;
 }
 
 #menuToggle span:nth-last-child(2)
 {
   -webkit-transform-origin: 0% 100%;
           transform-origin: 0% 100%;
 }
 
 /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
 #menuToggle input:checked ~ span
 {
   opacity: 1;
   -webkit-transform: rotate(45deg) translate(-2px, -1px);
           transform: rotate(45deg) translate(-2px, -1px);
   background: #232323;
 }
 
 /*
  * But let's hide the middle one.
  */
 #menuToggle input:checked ~ span:nth-last-child(3)
 {
   opacity: 0;
   -webkit-transform: rotate(0deg) scale(0.2, 0.2);
           transform: rotate(0deg) scale(0.2, 0.2);
 }
 
 /*
  * Ohyeah and the last one should go the other direction
  */
 #menuToggle input:checked ~ span:nth-last-child(2)
 {
   -webkit-transform: rotate(-45deg) translate(0, -1px);
           transform: rotate(-45deg) translate(0, -1px);
 }
 
 /*
  * Make this absolute positioned
  * at the top left of the screen
  */
 #menu
 {
   position: absolute;
   width: 300px;
   margin: -100px 0 0 -50px;
   padding: 50px;
   padding-top: 125px;
   
   background: #ededed;
   list-style-type: none;
   -webkit-font-smoothing: antialiased;
   /* to stop flickering of text in safari */
   
   -webkit-transform-origin: 0% 0%;
   
           transform-origin: 0% 0%;
   -webkit-transform: translate(-100%, 0);
           transform: translate(-100%, 0);
   
   transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 }
 
 #menu li
 {
   padding: 10px 0;
   font-size: 22px;
 }
 
 /*
  * And let's slide it in from the left
  */
 #menuToggle input:checked ~ ul
 {
   -webkit-transform: none;
           transform: none;
 }
.care_dashboard {

    margin: 50px;
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-column-gap: 50px;
    -webkit-column-gap: 50px;
            column-gap: 50px;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-template-rows: repeat(20, 100px);
    overflow-x: hidden;
    max-width: 1520px;
    
}

.ResidentCard {
  background-color: var(--primaryColour);
  color: var(--lighthighlightColour);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  grid-gap: 7px;
  gap: 7px;
  max-width: 300px;
  max-height: 200px;
  cursor: pointer;
}

.ResidentCard .avatar {
  width: 20%;
}

.ResidentCard .avatar img {
  width: 90%;
  vertical-align: middle;
  
  border-radius: 50%;
}
.ResidentCard .details {
  display: flex;
  flex-direction: column;
}
.resident_details_container {
  /*
  background-color: var(--primaryColour);
  */
  color: var(--detailsTextColour);
  
  padding: 10px;
 
  display: grid;
  grid-template-columns: 100px 200px;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  grid-row-gap: 5px;
  row-gap: 5px;
  grid-template-rows: repeat(5px);
  overflow-x: hidden;
  margin-bottom: 50px;

}

.resident_header{
  display: flex;
  flex-direction: row;

}

.resident_avatar {
  width: 150px;
  overflow: hidden;
}

.resident_avatar img {
  width: 90%;
  vertical-align: middle;
  border-radius: 50%;
}

.resident_container {
  width: 1000px;
}

.resident_container hr {
  width: 100%;
}

.resident_content {

}

.resident_nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.resident_nav .options {
  background-color: var(--primaryColour);
  color: var(--lighthighlightColour);
  padding: 5px;
  border-radius: 6px;
  min-width: 70px;
  text-align: center;

}


.resident_call_screen {
  border: 1px solid lightgrey;
  border-radius: 6px;
  margin: 10px;
  padding: 10px; 
}
.LiveDashboard_Container{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
}

.livecallheader {
    padding-left: 60px;
    position: relative;
    font-size: 20px;
    color: gray;
}


.dashboard_call_summary_layout{
    display: grid;
    grid-template-columns: auto 150px auto auto;
    
}


.dashboard_completed_calls_item { 
    font-size: 2.5vh; 
    font-weight: 300;
}


.dashboard_All_Calls {
    background-color: #4cc1bd;
}
.dashboard_Call {
    background-color: #F4804D;
}
  
  .dashboard_Emergency {
    background-color: #EC1848;
  }
  
  .dashboard_Assistance {
    background-color: #F8DA3C;
  }
  
  .dashboard_Accessory {
    background-color: #914397;
  }
  
  .dashboard_Attendance {
    background-color: #93C967;
  }

  .dashboard_Care_Call {
    background-color: rgb(225, 21, 131);
  }

  .dashboard_Visit {
    background-color: rgb(1, 87, 63);
  }
.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
.dashboard_completed_calls_container {
  border-radius: 6px;
  padding-left: 10px;
  cursor: pointer;
  text-align: left;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  height: 45px;
  line-height: 45px;
}

.dashboard_completed_calls_container img { align-self: center; }

.dashboard_call_summary_layout{
    display: grid;
    grid-template-columns: auto 150px auto auto;
    
}


.dashboard_completed_calls_item { 
    font-size: 2.5vh; 
    font-weight: 300;
}

.dashboard_call_summary_container{
  min-width: 320px;
  max-width: 600px;
  color: white;
  flex-grow: 3;
  margin-top: 20px;
}

.dashboard_All_Calls {
    background-color: #4cc1bd;
}
.dashboard_Call {
    background-color: #F4804D;
}
  
  .dashboard_Emergency {
    background-color: #EC1848;
  }
  
  .dashboard_Assistance {
    background-color: #F8DA3C;
  }
  
  .dashboard_Accessory {
    background-color: #914397;
  }
  
  .dashboard_Attendance {
    background-color: #93C967;
  }

  .dashboard_Care_Call {
    background-color: rgb(225, 21, 131);
  }

  .dashboard_Visit {
    background-color: rgb(1, 87, 63);
  }
.live_call_container {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 320px;
    max-width: 400%;
    width: auto;
}

.live_call_button {
    margin-left: 10px;
    margin-right: 10px;   
    border-radius: 8px;
    border: none;
    color: white;
    padding: 2px;
    box-shadow: 0px -5px 15px rgba(50, 50, 50, 0.15);
    margin-bottom: 2px;
    width: 96%;
    /*
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: top;
    position: relative;
    width: auto;
    */
}

.live_call_card {
    width: 100%;
    display: grid;
    grid-template-columns: 40px auto 70px;
    grid-template-rows: auto;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
    margin: 3px;
  }

  
  .caretooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #4cc1bd; /* If you want dots under the hoverable text */
    margin:1px;
}

.caretooltip .caretooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #4cc1bd;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    border: 1px solid white;
    position: absolute;
    z-index: 1;
}

.text-align-left {
    text-align: left;
}

.care_selected { height:25px }

.flex-column {
    display: flex;
    flex-direction: column;
  }
  
.flex-row {
    display: flex;
    flex-direction: row;
 }

.dashboard_item_section {
    /*border: 1px solid gray;*/
    width: 100%;
    padding: 3px;
    margin-top: 10px;
  }

/* Show the tooltip text when you mouse over the tooltip container */
.caretooltip:hover .caretooltiptext {
    visibility: visible;
}


.caretooltip_comment {
    justify-content:center;
    height:100%;
}

.caretooltip_comment .caretooltiptext_comment {
    visibility: hidden;
    width: 120px;
    background-color: darkgray;
    color: #fff;
    text-align: center;
    padding: 1px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.caretooltip_comment:hover .caretooltiptext_comment {
    visibility: visible;
}

.dashboard_careclick_comment_icon {
    background-color: lightgray;
    color: darkgray;
    font-size: 15px;
    font-weight: bold;
    height: 20px;
    width: 20px;
    border-radius:4px;
    margin:1px;
}

.caretooltiptext_comment_title{
    font-weight:bold;
    font-size: 14px;
    color:white;
    text-align:left;
    padding-left: 10px;        
}

.caretooltiptext_comment_text{
    background-color:white;
    color:darkgray;
    margin: 2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding:2px;
    text-align:left;
    padding-left: 10px;
}
@media screen and (max-width: 500px) {
    .menuItem_container, .main_content_menu { 
        display: none; 
    }
    

    .LiveDashboard_Container {
       
        /*flex-direction: column;*/
    }
}
/* Theming */ /* import font */

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background-color: var(--bodyBGColour);
    font-family: "Poppins", sans-serif;
}
a{
    text-decoration: none;
}
ul{
    list-style: none;
}

/* Header */
.menu_user_container{
    display: flex;
    flex-direction: row;
}

/* Header */
.header{
    background-color: var(--headerBGColour);
    box-shadow: 1px 1px 5px 0px var(--chartTitleColour);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
/* Logo */
.logo{
    display: inline-block;
    color: var(--primaryColour);
    font-size: 60px;
    margin-left: 10px;
}

/* Nav menu */
.nav{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: var(--primaryColour);
    overflow: hidden;

}
.menu a{
    display: block;
    padding: 30px;
    color: var(--chartTitleColour);
}
.menu a:hover{
    background-color: var(--lighthighlightColour);
}
.nav{
    max-height: 0;
    transition: max-height .5s ease-out;
}

/* Menu Icon */
.hamb{
    cursor: pointer;
    float: right;
    padding: 40px 20px;
}/* Style label tag */

.hamb-line {
    background: var(--primaryColour);
    display: block;
    height: 2px;
    position: relative;
    width: 24px;

} /* Style span tag */

.hamb-line::before,
.hamb-line::after{
    background: var(--primaryColour);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}
.hamb-line::before{
    top: 5px;
}
.hamb-line::after{
    top: -5px;
}

.side-menu {
    display: none;
} /* Hide checkbox */



/* Toggle menu icon */
.side-menu:checked ~ nav{
    max-height: 100%;
}
.side-menu:checked ~ .hamb .hamb-line {
    background: transparent;
}
.side-menu:checked ~ .hamb .hamb-line::before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top:0;
}
.side-menu:checked ~ .hamb .hamb-line::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top:0;
}


/* Responsiveness */
@media (min-width: 768px) {
    .nav{
        max-height: none;
        top: 0;
        position: relative;
        float: right;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background-color: transparent;
    }
    .menu li{
        float: left;
    }
    .menu a:hover{
        background-color: transparent;
        color: var(--gray);

    }

    .hamb{
        display: none;
    }
}

