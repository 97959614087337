div.tooltip {
    position: absolute;
    text-align: center;
    padding: .4rem;
    background: #FFFFFF;
    color: #313639;
    border: 1px solid #313639;
    border-radius: 8px;
    pointer-events: none;
    font-size: 1rem;
    font-style: italic;
}