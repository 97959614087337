
:root {
  --primaryColour: #4cc1bd;
  --lighthighlightColour: white;
  --chartTitleColour: grey;
  --chartSubTitleColour: grey;
  --chartTickColour: grey;
  --chartTickColour: grey;
  --secondaryColour: #dcf5f3;
  --chartBarTitleColour: grey;
  --detailsTextColour: grey;
  --bodyBGColour: white;
  --headerBGColour: white;
  --chartBGColour: lightgray;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.menuItem_container {

  color: grey;
  font-size: 18px;

}

.menuItem_container div {
  padding-top: 10px;
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}*/

.main_logo_header { width: 150px; }

.header_menu_options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 265px;
  color: #4cc1bd;
  align-items: center;
}



.header_menu_options * { margin-left: 15px; }

.main_content_organiser {
  display: flex;
  flex-direction: row;
}



.footer {
    background-color: var(--primaryColour);
    position: sticky;
    width: 100%;
    height: 25px;
    display: none;
    flex-direction: row;
    justify-content: center;
    margin-top: auto;
    bottom: 0px;
    /*display: none;*/
   
}

.main_content_menu{
  padding: 20px;
  min-width: 100px;
  display: flex;
  flex-direction: row;
}

.main_content_organiser {margin-bottom: 40px;}



@media screen and (max-width: 1000px) {
  
  .top_level .main_content_menu {display: none;}

  .footer {display: flex;}

  .main_content_organiser .main_content_menu {display: none;}

  

  .footer .main_content_menu{
    background-color: var(--primaryColour);
    color: white;
    position: absolute;
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    bottom: 0px;
    display: flex;
  }



  .footer .main_content_menu * {
    margin-left: 10px;
    margin-right: 10px;
  }

  .main_content_organiser {
    height: 90%;
    overflow-y: scroll;
  }
 
}